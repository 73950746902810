<script lang="ts">
	import { writable } from 'svelte/store';
	import PlayerCard from './PlayerCard.svelte';
	import Portal from '$lib/components/Portal.svelte';
	import {
		top as topoffset,
		left as leftoffset,
		playercard_user,
		playercard_active,
		playercard_hover,
		playercard_direction
	} from '$lib/stores/playercard';
	import type { User } from '$lib/stores/user';
	import Avatar from '$lib/components/ui/Avatar.svelte';

	
	interface Props {
		props?: any;
		disabled?: boolean;
		direction?: 'left' | 'right';
		display_name?: boolean;
		user?: User | null;
		avatar_size?: 'small' | 'medium' | 'large' | 'smallest';
	}

	let {
		props = undefined,
		disabled = false,
		direction = 'left',
		display_name = true,
		user = $bindable(null),
		avatar_size = 'medium'
	}: Props = $props();

	if (props) {
		user = { ...props, id: props.user_id };
	}

	let isPlayerCardHover = writable(false);
	// let isPlayerImgHoover = false;

	// let playerCard: PlayerCard | null = null;
	// let element = null;

	// function handleImgEnter(image) {
	// 	if (playerCard) {
	// 		return;
	// 	}
	// 	const { top, left } = image.target.getBoundingClientRect();
	// 	const offsetX = left + window.scrollX;
	// 	const offsetY = top + window.scrollY;

	// 	element = document.createElement('div');
	// 	document.body.appendChild(element);

	// 	element.style.position = 'absolute';
	// 	element.style.left = offsetX + 'px';
	// 	element.style.top = offsetY + 'px';

	// 	element.addEventListener('mouseenter', (event) => {
	// 		handleCardHoover();
	// 	});

	// 	element.addEventListener('mouseleave', (event) => {
	// 		handleCardLeave();
	// 	});

	// 	let testUser = {
	// 		props: {
	// 			direction: direction,
	// 			user
	// 		}
	// 	};

	// 	playerCard = new PlayerCard({
	// 		target: element,
	// 		props: testUser
	// 	});

	// 	isPlayerImgHoover = true;
	// 	console.log('isPlayerImgHoover is true');
	// }

	// function handleCardHoover() {
	// 	isPlayerCardHoover = true;
	// 	console.log('isPlayerCardHoover is true');
	// }

	// function handleImgLeave() {
	// 	setTimeout(() => {
	// 		if (isPlayerCardHoover || isPlayerImgHoover) {
	// 			return;
	// 		}
	// 		console.log('destroying');
	// 		playerCard?.$destroy();
	// 		playerCard = null;
	// 		element?.remove();
	// 	}, 100);
	// 	isPlayerImgHoover = false;
	// }

	// function handleCardLeave() {
	// 	setTimeout(() => {
	// 		if (isPlayerImgHoover || isPlayerCardHoover) {
	// 			return;
	// 		}
	// 		console.log('destroying');
	// 		playerCard?.$destroy();
	// 		playerCard = null;
	// 		element?.remove();
	// 	}, 100);
	// 	isPlayerCardHoover = false;
	// }

	function enterCard(event: CustomEvent<any>) {
		if (disabled) return;

		const { top, left } = (event.detail.target as HTMLElement)?.getBoundingClientRect();
		const offsetX = left + window.scrollX;
		const offsetY = top + window.scrollY;
		// console.log(' left ', left, ' top ', top, ' offsetX ', offsetX, ' offsetY ', offsetY);
		$playercard_hover = true;
		$topoffset = offsetY;
		$leftoffset = offsetX;
		$playercard_user = user;
		$playercard_direction = direction;
		$isPlayerCardHover = true;
	}

	function leaveCard() {
		if (disabled) return;

		$playercard_hover = false;
		// setTimeout(() => {
		// 	if ($isPlayerCardHoover) {
		// 		return;
		// 	}
		// 	$isPlayerCardHoover = false;
		// }, 100);
		// $isPlayerCardHoover = false;
	}
</script>

<div class="space-x-2 player">
	{#if user?.avatar}
		<Avatar
			username={user?.username}
			src={user?.avatar}
			delayMs={25}
			size={avatar_size}
			on:mouseenter={enterCard}
			on:mouseleave={leaveCard}
		/>
	{:else}
		<Avatar
			username={user?.username}
			delayMs={25}
			size={avatar_size}
			on:mouseenter={enterCard}
			on:mouseleave={leaveCard}
		/>
	{/if}

	{#if display_name}
		<a href="/profile/{user?.username}" class="text-white">{user?.username}</a>
	{/if}
</div>

<style lang="scss">
	.player {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 16px;
	}

	a {
		color: #fff;
		text-decoration: none;
	}
</style>
