import { get, writable, type Writable } from 'svelte/store';
import { navigating } from '$app/stores';
import type { User } from './user';

// Display
const debounce_value = 150;

export const playercard_debounce_timer: Writable<null | NodeJS.Timer> = writable(null);
export const playercard_active = writable(false);
export const playercard_hover: Writable<boolean> = writable(false);
// Automatically close the playercard when navigating
if (typeof window !== 'undefined') {
	navigating.subscribe(() => {
		playercard_active.set(false);
	});
}

// Debounce
playercard_hover.subscribe((boolean) => {
	if (boolean) {
		playercard_active.set(true);
		if (get(playercard_debounce_timer)) {
			// Get the current timer and clear it
			const number = get(playercard_debounce_timer);
			clearInterval(number as unknown as number);
		}
	} else {
		const timer = setInterval(() => {
			playercard_active.set(false);
			clearInterval(timer);
		}, debounce_value);
		playercard_debounce_timer.set(timer);
	}
});

// Card
export const playercard_user: Writable<User | null> = writable(null);

// Position
export const playercard_direction: Writable<'right' | 'left'> = writable('left');
export const top = writable(0);
export const left = writable(0);
